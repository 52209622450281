export const TITLE = `Immigration
Made Simple`;
export const SUBTITLE = `Reach your immigration goals confidently
with Rēginter Global Mobility`;
export const HERO_CTA = 'Book a free consultation!';
export const BOTTOM_TITLE = 'We’re free to try';
export const BOTTOM_SUBTITLE = `You only pay a fee if you decide to work with us.
Your first consultation is always free!`;

// PAGE SECTIONS

export const SECTION_STEPS = {
  title: 'Immigration process in',
  steps: [
    'Book a call with us to speak about your goals and needs',
    'Get a personalized step-by-step immigration plan',
    'Apply (with our support)',
    'Get your process approved!',
  ],
};

export const SECTION_PERKS = {
  title: 'We’re free to try',
  subtext: `You only pay a fee if you decide to work with us. Your
  first consultation is always free!`,
  sections: [
    {
      title: 'We move at your speed',
      subtitle: `You can count on us moving fast without sacrificing quality.
    We pay attention to details even on a tight deadline.`,
    },
    {
      title: 'We offer a high-touch service',
      subtitle: `You will have our support at all times until your immigration
    process is completed.`,
    },
    {
      title: 'We work with all immigration streams',
      subtitle: `Our immigration experts have worked with the most popular
    immigration and sponsorship processes and have a proven
    track record of success.`,
    },
    {
      title: 'We help smart companies and candidates',
      subtitle: `Whether you are a company or a person, we can create a
    personalized strategy for you to reach your immigration goals,
    always meeting your expectations, timeline, and budget.`,
    },
  ],
};

export const SECTION_CONTACT_FORM = {
  fields: [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      placeholder: 'Your first name',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      placeholder: 'Your last name',
      required: true,
    },
    {
      label: 'Work Email',
      name: 'email',
      type: 'email',
      placeholder: 'john@example.com',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      type: 'tel',
      placeholder: '(+1) 000 000 0000',
    },
    {
      label: 'Company Website',
      name: 'website',
      type: 'url',
      placeholder: 'https://example.com',
    },
    {
      label: 'Which immigration process are you interested in?',
      name: 'roleToFill',
      type: 'select',
      required: true,
      options: [
        {
          placeholder: 'Select the imigration process',
        },
      ],
    },
  ],
  submitText: 'Talk with our team',
  confirmationMessage: {
    title: 'Thank you for signing up to Rēginter!',
    subtitle: 'We will be in touch with you shortly',
    list: [
      {
        key: 0,
        text: `We’ll schedule a call to get to know you and
        your company better and introduce you to our service.`,
      },
      {
        key: 1,
        text: `You’ll get access to a curated list of
        candidates, matched to your preferences,
        who are actively searching for jobs.`,
      },
      {
        key: 2,
        text: `You’ll start interviewing – we will support
        you throughout the hiring process and stay
        one step ahead of your hiring goals.`,
      },
      {
        key: 3,
        text: 'Happy hiring!',
      },
    ],
  },
};
