import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useBEM } from '../../../../customHooks';

import styles from './Form.scss';

export const Form = ({ fields, submitText, handleSubmit }) => {
  const cx = classNames.bind(styles);
  const bem = useBEM('form');
  const selectFields = fields.filter((field) => field.type === 'select');
  const inputFields = fields.filter((field) => field.type !== 'select');

  return (
    <form className={cx(bem.block)} onSubmit={handleSubmit}>
      <div className={cx(bem.element('fields'))}>
        {
            inputFields.map((input) => (
              <div key={`input-${input.name}`}>
                <label htmlFor={input.name}>{ `${input.label} ${input.required ? '*' : ''}` }</label>
                <input
                  required={input.required}
                  name={input.name}
                  type={input.type}
                  placeholder={input.placeholder}
                />
              </div>
            ))
          }
        {
            selectFields.map((select) => (
              <div key={`select-${select.name}`}>
                <label htmlFor={select.name}>{`${select.label} ${select.required ? '*' : ''}`}</label>
                <select name={select.name} required={select.required}>
                  {
                  select.options.map((option) => {
                    if (option.placeholder) {
                      return (
                        <option key="placeholder" value="" disabled selected defaultValue>{ option.placeholder }</option>
                      );
                    }
                    return (
                      <option key={`value-${option.value}`} value={option.value}>{option.text}</option>
                    );
                  })
                  }
                </select>
              </div>
            ))
          }
      </div>
      <button type="submit">{ submitText }</button>
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
  })).isRequired,
  submitText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
