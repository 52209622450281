import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useBEM } from '../../customHooks';
import styles from './Perks.scss';

export const Perks = ({ sections }) => {
  const cx = classNames.bind(styles);
  const bem = useBEM('perks');

  return (
    <section className={cx(bem.block)}>
      <div className={`${cx(bem.element('sections'))} container columns is-multiline is-desktop is-gapless`}>
        {
          sections.map((section) => (
            <div className="column is-6 is-full-touch" key={section.title}>
              <div className={`${cx(bem.element('section'))}`}>
                <h3>{ section.title }</h3>
                <p>{ section.subtitle }</p>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
};

Perks.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  })).isRequired,
};
