import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useBEM, useScrollToTop } from '../../customHooks';
import { Navbar, Perks, Footer } from '../../components';
import {
  BOTTOM_SUBTEXT,
  BOTTOM_TITLE,
  SECTION_PERKS,
  SECTION_SERVICES,
  SUBTITLE,
  TITLE,
} from './constants';

import styles from './Home.scss';

export const Home = () => {
  const cx = classNames.bind(styles);
  const bem = useBEM('home');

  // Resets page scroll to top when loaded
  useScrollToTop();

  return (
    <main className={cx(bem.block)}>
      <Navbar logoClass={cx(bem.element('logo'))} />
      <section className={`${cx(bem.element('banner'))}`}>
        <div className="container columns">
          <div className="column">
            <header>
              <h1>
                { TITLE.split('\n').map((string) => <span key={string}>{string}</span>) }
              </h1>
              <p role="doc-subtitle">
                { SUBTITLE.split('\n').map((string) => <span key={string}>{string}</span>) }
              </p>
            </header>
          </div>
        </div>
      </section>
      <section className={cx(bem.element('services'))}>
        <div className="container columns is-multiline is-desktop is-gapless">
          {
            Object.entries(SECTION_SERVICES).map((service) => {
              const [serviceKey, data] = service;
              return (
                <div key={data.title} className="column is-6 is-full-touch">
                  <div className={`${cx(bem.element('link'))}`}>
                    <Link to={`/${serviceKey}`}>
                      <div className="service-container">
                        <div className="service-icon" />
                        <div className="service-content">
                          <h2>{ data.title }</h2>
                          <p>{ data.subtitle }</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })
          }
        </div>
      </section>
      <section className={`${cx(bem.element('bottom'))}`}>
        <div className="container">
          <Perks {...SECTION_PERKS} />
          <div className="columns is-multiline is-desktop is-gapless">
            <div className="column">
              <hgroup>
                <h2>{ BOTTOM_TITLE }</h2>
                <p>
                  { BOTTOM_SUBTEXT }
                </p>
              </hgroup>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};
