import React from 'react';
import classNames from 'classnames/bind';
import { useBEM, useScrollToTop } from '../../customHooks';
import {
  About,
  ContactForm,
  Perks,
  Footer,
  Navbar,
  Steps,
} from '../../components';
import {
  BOTTOM_SUBTITLE,
  BOTTOM_TITLE,
  HERO_CTA,
  SECTION_ABOUT,
  SECTION_CONTACT_FORM,
  SECTION_PERKS,
  SECTION_STEPS,
  SUBTITLE,
  TITLE,
} from './constants';
import styles from './Candidates.scss';

export const Candidates = () => {
  const cx = classNames.bind(styles);
  const bem = useBEM('candidates');

  // Resets page useScroll to top when loaded
  useScrollToTop();

  return (
    <main className={cx(bem.block)}>
      <Navbar logoClass={cx(bem.element('logo'))} />
      <section className={`${cx(bem.element('banner'))}`}>
        <div className="container columns">
          <div className="column">
            <header className={cx(bem.element('top'), 'top')}>
              <hgroup>
                <h1>
                  { TITLE.split('\n').map((string) => <span key={string}>{string}</span>)}
                </h1>
                <p role="doc-subtitle" className={cx(bem.element('subtitles'))}>
                  { SUBTITLE.split('\n').map((string) => <span key={string}>{string}</span>)}
                </p>
                <p className="links">
                  <a href="#contact-form" role="button" className={cx('modal-cta')}>{ HERO_CTA }</a>
                </p>
              </hgroup>
            </header>
          </div>
        </div>
      </section>
      <About page="candidates" sections={SECTION_ABOUT} />
      <Steps page="candidates" {...SECTION_STEPS} />
      <section className={cx(bem.element('bottom'))}>
        <div className="container">
          <Perks sections={SECTION_PERKS} />
          <div id="contact-form" className="columns is-multiline is-desktop is-gapless">
            <div className="column">
              <hgroup>
                <h2>{ BOTTOM_TITLE }</h2>
                <p>
                  { BOTTOM_SUBTITLE }
                </p>
              </hgroup>
              <ContactForm {...SECTION_CONTACT_FORM} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};
