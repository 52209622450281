import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Typed from 'typed.js'; // https://github.com/mattboldt/typed.js

import styles from './style.scss';

export const TypedTitle = ({ titles }) => {
  const cx = classNames.bind(styles);
  const typed = () => (
    new Typed('.typedTitles', {
      strings: titles,
      backDelay: 2000,
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    })
  );

  // Reset component when changing pages
  // to avoid clumping behaviour
  useEffect(() => {
    const typedTitle = typed();
    return () => typedTitle.destroy();
  }, [titles]);

  return (
    <h1 className={cx('typed-title')}>
      <span>Hire Global</span>
      <span className="typedTitles" />
    </h1>
  );
};

TypedTitle.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
