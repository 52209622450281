export const TYPED_TITLES = [
  'Leaders',
  'Directors',
  'Managers',
  'Vice-Presidents',
  'C-level Executives',
  'Board Members',
];
export const SUBTITLE = `Expand your candidate pool by 10x
and increase diversity in your team.`;
export const BOTTOM_TITLE = 'We’re free to try';
export const BOTTOM_SUBTITLE = `You only pay a fee when you hire one of
our candidates. Your success is our success.`;

// PAGE SECTIONS
export const SECTION_ABOUT = {
  leverage_global_talent: {
    icon: 'star',
    title: 'Leverage global talent',
    subtitle: `Join Rēginter to gain access to a curated pool of local
    and international highly-qualified engineers, each
    pre-vetted for experience, skills, and all your specific
    requirements.`,
  },
  hire_faster: {
    icon: 'speed',
    title: 'Hire faster',
    subtitle: `Going global means you are no longer limited by the
    shortage of skilled engineers in Canada. Meet 10x more
    candidates and find your next hire in half the time.`,
  },
  decorative_image_01: {
    image_name: 'decorative_image_01.png',
  },
  decorative_image_02: {
    image_name: 'decorative_image_02.png',
  },
  promote_diversity: {
    icon: 'diverse',
    title: 'Promote diversity in your company',
    subtitle: `Workplace diversity can help you evolve, innovate,
    problem-solve, and be more efficient. Our unique
    method will connect you to candidates with new
    perspectives and approaches.`,
  },
  salary_wars: {
    icon: 'money',
    title: 'No more salary bidding wars',
    subtitle: `Are you tired of competing with other companies for
    the same candidate? Look beyond the local market,
    and you will find that you will never get into a salary
    bidding war again. `,
  },
};

export const SECTION_STEPS = {
  title: 'Hire your next leader in',
  steps: [
    'Book a call with us to speak about your roles',
    'Get a curated candidate shortlist',
    'Set up interviews with your favorite candidates',
    'Make an offer!',
  ],
};

export const SECTION_PERKS = {
  title: 'We’re free to try',
  subtext: `You only pay a fee when you hire one of our candidates.
  Your success is our success.`,
  sections: [
    {
      title: 'We move at your speed',
      subtitle: `Our process is fast, but never rushed.
    Tell us how quickly you would like to move, and we will be there for you.`,
    },
    {
      title: 'We offer a high-touch service',
      subtitle: `You will have our support at all times when looking for the
    perfect job or candidate.`,
    },
    {
      title: 'Our service goes beyond recruitment',
      subtitle: `Need help with salary negotiation, terms of offer, contracts,
    reference checks, international hiring, or relocation? We got you.`,
    },
    {
      title: 'We help smart companies and candidates',
      subtitle: `Whether you are a candidate, a startup or a large enterprise,
    we can help you reach your goals.`,
    },
  ],
};

export const SECTION_CONTACT_FORM = {
  fields: [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      placeholder: 'Your first name',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      placeholder: 'Your last name',
      required: true,
    },
    {
      label: 'Work Email',
      name: 'email',
      type: 'email',
      placeholder: 'john@example.com',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      type: 'tel',
      placeholder: '(+1) 000 000 0000',
    },
    {
      label: 'Company Website',
      name: 'website',
      type: 'url',
      placeholder: 'https://example.com',
      required: true,
    },
    {
      label: 'Target Role to Fill',
      name: 'roleToFill',
      type: 'text',
      required: true,
    },
  ],
  submitText: 'Talk with our team',
  confirmationMessage: {
    title: 'Thank you for signing up to Rēginter!',
    subtitle: 'We will be in touch with you shortly',
    list: [
      {
        key: 0,
        text: `We’ll schedule a call to get to know you and
        your company better and introduce you to our service.`,
      },
      {
        key: 1,
        text: `You’ll get access to a curated list of
        candidates, matched to your preferences,
        who are actively searching for jobs.`,
      },
      {
        key: 2,
        text: `You’ll start interviewing – we will support
        you throughout the hiring process and stay
        one step ahead of your hiring goals.`,
      },
      {
        key: 3,
        text: 'Happy hiring!',
      },
    ],
  },
};
