import { useLayoutEffect } from 'react';

export const useUpdateOnScroll = (element) => useLayoutEffect(() => {
  document.addEventListener('scroll', (() => {
    const navbar = document.querySelector(`.${element}`);

    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      navbar.classList.replace(`${element}--inactive`, `${element}--active`);
    } else {
      navbar.classList.replace(`${element}--active`, `${element}--inactive`);
    }
  }));
}, []);
