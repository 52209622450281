import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useBEM } from '../../customHooks';

import styles from './Footer.scss';

export const Footer = () => {
  const cx = classNames.bind(styles);
  const bem = useBEM('footer');

  return (
    <footer className={cx(bem.block)}>
      <section className={cx(bem.element('social-medias'))}>
        <ul>
          <li>
            <a
              data-social="linkedin"
              href="https://www.linkedin.com/company/r%C4%93ginter/"
              aria-label="Social media link to Linkedin"
              target="_blank"
              rel="noopenner noreferrer"
            >
              Linkedin
            </a>
          </li>
        </ul>
      </section>
      <section className={cx(bem.element('location-contact'))}>
        <h2>Location & Contanct</h2>
        <p>
          <span>Greater Toronto Area</span>
          <span>ON, Canada</span>
        </p>
        <p>
          <a href="mailto:gui@reginter.ca">gui@reginter.ca</a>
        </p>
        <p>
          <a href="tel:+1 519 643 8752">+1 519 643 8752</a>
        </p>
      </section>
      <section className={cx(bem.element('services'))}>
        <h2>Our Services</h2>
        <ul>
          <li>
            <Link to="/candidates">Candidates</Link>
          </li>
          <li>
            <Link to="/executive">Executive</Link>
          </li>
          <li>
            <Link to="/engineering">Engineering</Link>
          </li>
          <li>
            <Link to="/global_mobility">Global Mobility</Link>
          </li>
        </ul>
      </section>
    </footer>
  );
};
