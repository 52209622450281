import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useBEM } from '../../customHooks';
import styles from './Steps.scss';

export const Steps = ({ page, title, steps }) => {
  const cx = classNames.bind(styles);
  const bem = useBEM('steps');
  const buildImage = () => {
    // Need to dynamically import image
    const imageSrc = require(`../../assets/images/${page}/steps/steps_photo.png`);
    return <img src={imageSrc.default} alt="" />;
  };

  return (
    <section className={cx(bem.block)}>
      <div className="container columns is-multiline is-desktop is-gapless">
        <div className="column is-6 is-full-touch">
          <h2>
            <span>{ title }</span>
            <span>4 easy steps</span>
          </h2>
          <ul>
            {
                steps.map((step) => (
                  <li key={step} className={cx(bem.element('step'))}>
                    <span>{ step }</span>
                  </li>
                ))
              }
          </ul>
        </div>
        <div className="column is-6 is-full-touch">
          { buildImage() }
        </div>
        <div className="column is-full">
          <a href="#contact-form" role="button" className={cx('modal-cta')}>Book a call!</a>
        </div>
      </div>
    </section>
  );
};

Steps.propTypes = {
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
