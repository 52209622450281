import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Appstyles from './App.scss';

ReactDOM.render(
  <React.StrictMode>
    <App classNames={Appstyles} />
  </React.StrictMode>,
  document.getElementById('root'),
);
