import React from 'react';
import classNames from 'classnames/bind';
import { useBEM, useScrollToTop } from '../../customHooks';
import {
  About,
  ContactForm,
  Perks,
  Footer,
  Navbar,
  Steps,
  TypedTitle,
} from '../../components';

import {
  SECTION_ABOUT,
  SECTION_PERKS,
  SECTION_STEPS,
  SECTION_CONTACT_FORM,
  SUBTITLE,
  TYPED_TITLES,
  BOTTOM_TITLE,
  BOTTOM_SUBTITLE,
} from './constants';
import styles from './Engineering.scss';

export const Engineering = () => {
  const cx = classNames.bind(styles);
  const bem = useBEM('engineering');

  // Resets page useScroll to top when loaded
  useScrollToTop();

  return (
    <main className={cx(bem.block)}>
      <Navbar logoClass={cx(bem.element('logo'))} />
      <section className={`${cx(bem.element('banner'))}`}>
        <div className="container columns">
          <div className="column">
            <header>
              <TypedTitle titles={TYPED_TITLES} />
              <p role="doc-subtitle" className={cx(bem.element('subtitles'))}>
                {
                  SUBTITLE.split('\n').map((string) => <span key={string}>{string}</span>)
                }
              </p>
              <p className="links">
                <a href="#contact-form" role="button" className={cx('modal-cta')}>Start hiring!</a>
                <a href="/candidates">I’m looking for a job!</a>
              </p>
            </header>
          </div>
        </div>
      </section>
      <About page="engineering" sections={SECTION_ABOUT} />
      <Steps page="engineering" {...SECTION_STEPS} />
      <section className={cx(bem.element('bottom'))}>
        <div className="container">
          <Perks {...SECTION_PERKS} />
          <div id="contact-form" className="columns is-multiline is-desktop is-gapless">
            <div className="column">
              <hgroup>
                <h2>{ BOTTOM_TITLE }</h2>
                <p>
                  { BOTTOM_SUBTITLE }
                </p>
              </hgroup>
              <ContactForm {...SECTION_CONTACT_FORM} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};
