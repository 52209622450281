import { useMemo } from 'react';

export const useBEM = (className) => useMemo(() => {
  const block = className;
  const element = (name) => `${block}__${name}`;
  const modifier = (name) => `${block}--${name}`;

  return {
    block,
    element,
    modifier,
  };
}, [className]);
