export const TITLE = `Find your dream job in
  Canada or Europe`;
export const SUBTITLE = `Sign up for free to unlock the opportunity
to join an international team in your ideal role.`;
export const HERO_CTA = 'Sign up now!';
export const BOTTOM_TITLE = 'Sign up to get hired';
export const BOTTOM_SUBTITLE = `Join Rēginter to connect with top international
companies. We're committed to your success.`;

// PAGE SECTIONS
export const SECTION_ABOUT = {
  expand_your_search: {
    icon: 'search',
    title: 'Expand your search',
    subtitle: `Go beyond your local market to access hundreds of
    leadership and engineering jobs abroad. After you sign up
    to Rēginter, we will present your profile to international
    companies looking to hire someone just like you.`,
  },
  sponsored_visa: {
    icon: 'airplane',
    title: 'Get a visa-sponsored job',
    subtitle: `Rēginter works with multiple companies
    in Canada and Europe looking to hire professionals from the most
    diverse backgrounds and sponsor their work visas.
    Whether you are an engineer or an executive, we have got relocation jobs for you.`,
  },
  decorative_image_01: {
    image_name: 'decorative_image_01.png',
  },
  decorative_image_02: {
    image_name: 'decorative_image_02.png',
  },
  personalized_advice: {
    icon: 'diverse',
    title: 'Receive personalized advice',
    subtitle: `At Rēginter, we respect our candidates. As soon as you
    start a hiring process with one of our partners, you will
    receive constant feedback and improvement tips.
    That’s right – feedback and personalized guidance for everyone.`,
  },
  immigration_support: {
    icon: 'check',
    title: 'Get immigration support',
    subtitle: `Our immigration consultant partners will be there for
    you through your relocation process. If you are
    interested in immigrating to Canada or Europe under
    other visa types, our team can also support you.`,
  },
};

export const SECTION_STEPS = {
  title: 'Get hired abroad in',
  steps: [
    'Sign up and complete your profile',
    'Book a virtual call with us to speak about your goals',
    'Get interviews from international companies',
    'Receive a job offer!',
  ],
};

export const SECTION_PERKS = [
  {
    title: 'We are always free',
    subtitle: `Yes, you read it right! Rēginter doesn’t cost candidates a
    dime – sign up, apply to jobs, and get hired for free. `,
  },
  {
    title: 'We work with diverse roles',
    subtitle: `Everyone from managers to directors, VPs, and C-level executives
    are welcome at Rēginter. The same goes for software, industrial,
    mechanical, chemical, civil, and other engineering roles.`,
  },
  {
    title: 'Our service goes beyond recruitment',
    subtitle: `Need help with salary negotiation, terms of offer, contracts,
    reference checks, or relocation? We got you.`,
  },
  {
    title: 'We offer a high-touch service',
    subtitle: `You will have our support at all times when looking for the
    perfect job and going through your immigration and relocation process.`,
  },
];
export const SECTION_CONTACT_FORM = {
  fields: [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      placeholder: 'Your first name',
      required: true,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      placeholder: 'Your last name',
      required: true,
    },
    {
      label: 'Primary Email',
      name: 'email',
      type: 'email',
      placeholder: 'john@example.com',
      required: true,
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      type: 'tel',
      placeholder: '(+1) 000 000 0000',
    },
    {
      label: 'What is your primary goal by registering at Rēginter?',
      name: 'primaryGoal',
      type: 'select',
      options: [
        {
          placeholder: 'Select the primary goal',
        },
        {
          value: 'engineer',
          text: 'I’m an engineer looking for a job',
        },
        {
          value: 'leader',
          text: 'I’m in a leadership role looking for a job',
        },
        {
          value: 'immigration',
          text: 'I’m only looking for immigration support',
        },
      ],
    },
  ],
  submitText: 'Sign up now',
  confirmationMessage: {
    title: 'Thank you for signing up to Rēginter!',
    subtitle: `You must now confirm your email address.
    Check your inbox (promotion and spam folders, too!)
    for the confirmation email. After it’s confirmed,
    here’s what you can expect:`,
    list: [
      {
        key: 0,
        text: `If you’re looking for a job, a Rēginter Talent Advisor will
        review your resume and invite you for an interview when
        we have a role that matches your profile.`,
      },
      {
        key: 1,
        text: `If you’re looking for immigration support, a Rēginter
        Immigration Consultant partner will book a free virtual
        meeting with you to discuss your goals. This may take
        from five to ten business days.`,
      },
    ],
  },
};
