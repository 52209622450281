import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {
  Home,
  Candidates,
  Engineering,
  Executive,
  GlobalMobility,
} from './pages';

export default function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/executive" component={Executive} />
          <Route path="/engineering" component={Engineering} />
          <Route path="/global_mobility" component={GlobalMobility} />
          <Route path="/candidates" component={Candidates} />
        </Switch>
      </Router>
    </>
  );
}
