import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useBEM } from '../../customHooks';

import styles from './ContactForm.scss';
import {
  ConfirmationMessage,
  ErrorMessage,
  Loading,
  Form,
} from './components';

export const ContactForm = ({ fields, submitText, confirmationMessage }) => {
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const cx = classNames.bind(styles);
  const bem = useBEM('contact-form');

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const elements = Array.from(e.target.elements).filter((el) => !!el.value);
    const payload = elements.reduce((inputs, input) => ({
      ...inputs,
      [`${input.name}`]: input.value,
    }), {});

    const postRequest = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    fetch(process.env.REACT_APP_SAVE_LEAD_ENDPOINT,
      postRequest).then((result) => {
      setLoading(false);
      if (result.ok) {
        setFormSent(true);
      } else {
        setFormErrors(true);
      }
      return result.status;
    }).catch(() => {
      setLoading(false);
      setFormErrors(true);
    });
  };

  return (
    <div className={cx(bem.block)}>
      {
        loading
        && <Loading />
      }
      {
        !loading && !formSent && !formErrors
        && <Form fields={fields} submitText={submitText} handleSubmit={handleSubmit} />
      }
      {
        !loading && formSent && <ConfirmationMessage {...confirmationMessage} />
      }
      {
        !loading && formErrors && <ErrorMessage />
      }
    </div>
  );
};

ContactForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
  })).isRequired,
  submitText: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};
