import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useBEM, useUpdateOnScroll } from '../../customHooks';
import styles from './Navbar.scss';

export const Navbar = ({ logoClass }) => {
  const cx = classNames.bind(styles);
  const bem = useBEM('navbar');

  const [showDropdownMobileMenu, setShowDropdownMobileMenu] = useState(false);

  const onBurgerMenuClick = (e) => {
    e.preventDefault();
    setShowDropdownMobileMenu(!showDropdownMobileMenu);
  };

  useUpdateOnScroll('main-menu');

  return (
    <section className="main-menu main-menu--inactive">
      <div className="container">
        <nav className={`navbar ${cx(bem.block, bem.modifier('inactive'))}`} role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link
              to="/"
              tabIndex={0}
              aria-label="Link to Home"
              className={logoClass}
            />

            <a href="/" role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="mainNavBar" onClick={onBurgerMenuClick}>
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>

          <div id="mainNavBar" className={`navbar-menu ${showDropdownMobileMenu ? 'is-active' : ''}`}>
            <div className="navbar-end">
              <div className={`navbar-item ${cx(bem.element('links'))}`}>
                <Link to="/executive">Executive</Link>
              </div>
              <div className={`navbar-item ${cx(bem.element('links'))}`}>
                <Link to="/engineering">Engineering</Link>
              </div>
              <div className={`navbar-item ${cx(bem.element('links'))}`}>
                <Link to="/global_mobility">Global Mobility</Link>
              </div>
              <div className={`navbar-item ${cx(bem.element('links'))}`}>
                <Link to="/candidates">Candidates</Link>
              </div>
              <div className={`navbar-item ${cx(bem.element('links'))}`}>
                <a className="navbar-item--button" href="#newsletter-form" role="button">Contact us</a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

Navbar.propTypes = {
  logoClass: PropTypes.string.isRequired,
};
