import React from 'react';
import PropTypes from 'prop-types';

export const ConfirmationMessage = (props) => {
  const { title, subtitle, list } = props;
  return (
    <div className="columns is-multiline is-desktop is-gapless">
      <div className="column is-12 is-full-touch">
        <hgroup>
          <h2>{ title }</h2>
          <p>{ subtitle }</p>
        </hgroup>
      </div>
      <div className="column is-6 is-full-touch steps">
        <ul>
          {
            list.map((item) => (
              <li key={`item-${item.key}`} className="steps__step">
                <span>{ item.text }</span>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="column is-6 is-full-touch confirmation">
        &nbsp;
      </div>
    </div>
  );
};

ConfirmationMessage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};
