import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useBEM } from '../../customHooks';
import styles from './About.scss';

export const About = ({ page, sections }) => {
  const cx = classNames.bind(styles);
  const bem = useBEM('about');

  const buildImage = (key, data) => {
    // Need to dynamically import image
    const imageSrc = require(`../../assets/images/${page}/${data.image_name}`);
    return <img key={key} src={imageSrc.default} alt="" />;
  };

  const buildsection = (key, data) => (
    <div key={key} className={`about--item ${cx(bem.element(`icon-${data.icon}`))}`}>
      <h2>{ data.title }</h2>
      <p>{ data.subtitle }</p>
    </div>
  );

  return (
    <section className={cx(bem.block)}>
      {
        Object.entries(sections).map((section) => {
          const [key, data] = section;
          return key.includes('image') ? buildImage(key, data) : buildsection(key, data);
        })
      }
    </section>
  );
};

About.propTypes = {
  page: PropTypes.string.isRequired,
  sections: PropTypes.objectOf(PropTypes.shape({
    data: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }),
  })).isRequired,
};
