import React from 'react';

export const ErrorMessage = () => (
  <div className="columns is-multiline is-desktop is-gapless">
    <div className="column is-12 is-full-touch error">
      &nbsp;
    </div>
    <div className="column is-12 is-full-touch">
      <hgroup>
        <h2>Whoops, that page is gone.</h2>
        <p>This means that an unexpected error happened while processing your request.</p>
        <p>
          Refresh the page, the problem may be temporary.
          If the problem persists, contact us and we’ll help you get you on your way.
        </p>
      </hgroup>
    </div>
  </div>
);
