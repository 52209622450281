export const TITLE = `Hiring?
Think Different.
Go Global`;
export const SUBTITLE = `Recruiting can be simple.
Leverage global talent to expand your talent pool.
Hire faster. Promote diversity. `;
export const BOTTOM_TITLE = 'We’re free to try';
export const BOTTOM_SUBTEXT = `Candidates never pay a dime. Companies only pay
a fee when hiring one of our candidates. Your success is our success.`;

// PAGE SECTIONS
export const SECTION_SERVICES = {
  executive: {
    title: 'Executive',
    subtitle: 'I want to hire global leaders',
  },
  engineering: {
    title: 'Engineering',
    subtitle: 'I want to hire skilled engineers',
  },
  global_mobility: {
    title: 'Global Mobility',
    subtitle: 'I want to immigrate or sponsor an immigrant',
  },
  candidates: {
    title: 'for Candidates',
    subtitle: 'I want to find my next opportunity',
  },
};

export const SECTION_PERKS = {
  title: "We're free to try",
  subtext: `Candidates never pay a dime. Companies only pay a fee
  when hiring one of our candidates. Your success is our success.`,
  sections: [
    {
      title: 'We move at your speed',
      subtitle: `Our process is fast, but never rushed.
    Tell us how quickly you would like to move, and we will be there for you.`,
    },
    {
      title: 'We offer a high-touch service',
      subtitle: `You will have our support at all times when looking for the
    perfect job or candidate.`,
    },
    {
      title: 'Our service goes beyond recruitment',
      subtitle: `Need help with salary negotiation, terms of offer, contracts,
    reference checks, international hiring, or relocation? We got you.`,
    },
    {
      title: 'We help smart companies and candidates',
      subtitle: `Whether you are a candidate, a startup or a large enterprise,
    we can help you reach your goals.`,
    },
  ],
};
